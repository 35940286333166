<template>
    <div class="page">
       <div class="background-box">
           <div class="list-cell" @click="$router.push(`my-user-authentication`)">
               <span>
                   基础信息修改
               </span>
               <van-icon name="arrow" class="cell-btn"/>
           </div>
           <div class="list-cell" @click="$router.push(`privacy-agreement`)">
               <span>
                   隐私协议
               </span>
               <van-icon name="arrow" class="cell-btn" />
           </div>
           <div class="list-cell" @click="$router.push(`service-agreement`)">
               <span>
                   服务协议
               </span>
               <van-icon name="arrow" class="cell-btn"/>
           </div>
       </div>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                params: {
                },

            }
        },
        mounted() {

        },
        methods: {

        },
        computed: {

        }
    }

</script>

<style lang="less" scoped>
    .background-box {
        width: 100%;
        min-height: calc(100vh - 0px);
        background: #FFFFFF;


            .list-cell{
                padding: 15px 15px 15px 10px;
                border-bottom: 1px solid #F2F2F2;
                span{
                    color: #212121;
                    font-size: 16px;
                    /*font-weight: 600;*/
                }
                .cell-btn{
                    color: #C3C3C3;
                    font-size: small;
                    float: right;
                    margin-left: -100%;
                    margin-top: 5px;
                }
            }

    }

</style>
